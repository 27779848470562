var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"service-block pad-tb bg-gradient7"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":200,"data-aos-duration":"1000"}},[_vm._m(1)]),_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":400,"data-aos-duration":"1000"}},[_vm._m(2)]),_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":600,"data-aos-duration":"1000"}},[_vm._m(3)]),_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":800,"data-aos-duration":"1000"}},[_vm._m(4)]),_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":1000,"data-aos-duration":"1000"}},[_vm._m(5)]),_c('div',{staticClass:"col-lg-4 col-sm-6 mt30",attrs:{"data-aos":"fadeIn","data-aos-delay":1200,"data-aos-duration":"1000"}},[_vm._m(6)])]),_c('div',{staticClass:"-cta-btn mt70"},[_c('div',{staticClass:"free-cta-title v-center",attrs:{"data-aos":"zoomInDown","data-aos-delay":1400,"data-aos-duration":"1000"}},[_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"common-heading ptag"},[_c('span',[_vm._v("We Deliver Our Best")]),_c('h2',[_vm._v("Why Choose Boxinall")]),_c('p',{staticClass:"mb30"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/teama.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Reliable Service. In House Team")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/link.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Trusted by People Like You")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/tech.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Complete Technical Competency")]),_c('p',[_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/hi.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Friendly & Cordial in Nature")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/badge.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Excellent Quality Delivered on Time and in Budget")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-block wide-sblock"},[_c('div',{staticClass:"s-card-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/icons/tin.svg"),"alt":"service"}})]),_c('div',{staticClass:"s-block-content"},[_c('h4',[_vm._v("Effective & Continuous Communication")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Let's Start a "),_c('span',[_vm._v("New Project")]),_vm._v(" Together")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn-main bg-btn2 lnk",attrs:{"href":"#"}},[_vm._v("Inquire Now"),_c('i',{staticClass:"fas fa-chevron-right fa-icon"}),_c('span',{staticClass:"circle"})])
}]

export { render, staticRenderFns }