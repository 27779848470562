<template>
    <footer>
        <!-- <div class="footer-row1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="email-subs">
                            <h3>Get New Insights Weekly</h3>
                            <p>News letter dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt.
                                Enter your email</p>
                        </div>
                    </div>
                    <div class="col-lg-6 v-center">
                        <div class="email-subs-form">
                            <form>
                                <input type="email" placeholder="Email Your Address" name="emails">
                                <button type="submit" name="submit" class="lnk btn-main bg-btn">Subscribe <i
                                        class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="footer-row2">
            <div class="container text-start">
                <div class="row justify-content-between">
                    <div class="col-lg-3 col-sm-6  ftr-brand-pp">
                        <a class="navbar-brand mt30 mb25" href="#"> <img src="../assets/images/black_logo.png"
                                alt="Logo" width="100" /></a>
                        <p>We offer 360° Mobile & Web solutions that help you reach your target market and transform
                            your ideas into top-notch products. Since 2018, we have dominated the market for mobile apps
                            and practical business and marketing solutions.

                        </p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfSPwE-wKtjYy8fT6ju4oNYX_VpPP_A542v965TBPIYaw1mDQ/viewform?usp=sf_link"
                            class="btn-main bg-btn3 lnk mt20">Vendor Registration <i
                                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <h5>Contact Us</h5>
                        <ul class="footer-address-list ftr-details">
                            <li>
                                <span><i class="fas fa-envelope"></i></span>
                                <p>Email <span> <a
                                            href="mailto:info@businessname.com">info@boxinallsoftech.com</a></span></p>
                            </li>
                            <li>
                                <span><i class="fas fa-phone-alt"></i></span>
                                <p>Phone <span> <a href="tel:+10000000000">+91 827-960-7423</a></span></p>
                            </li>
                            <li>
                                <span><i class="fas fa-map-marker-alt"></i></span>
                                <p>Address <span>Gaur city 2 16 Avenue, Noida, Uttar Pradesh,
                                        India, 201009</span></p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <h5>Services</h5>
                        <ul class="footer-address-list link-hover">
                            <li>Mobile App Development</li>
                            <li>Web Development</li>
                            <li>User Interface Design</li>
                            <li>Custum Software Development</li>
                            <li>CMS Development</li>
                            <li>OTT App Development</li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-sm-6 footer-blog-">
                        <h5>Latest Blogs</h5>
                        <div class="single-blog-">
                            <div class="post-thumb"><a href="#"><img src="../assets/images/blog/blog-small.jpg"
                                        alt="blog"></a></div>
                            <div class="content">
                                <p class="post-meta"><span class="post-date"><i class="far fa-clock"></i>April 15,
                                        2020</span></p>
                                <h4 class="title"><a href="blog-sngle.html">We Provide you Best &amp; Creative
                                        Consulting
                                        Service</a></h4>
                            </div>
                        </div>
                        <div class="single-blog-">
                            <div class="post-thumb"><a href="#"><img src="../assets/images/blog/blog-small.jpg"
                                        alt="blog"></a></div>
                            <div class="content">
                                <p class="post-meta"><span class="post-date"><i class="far fa-clock"></i>April 15,
                                        2020</span></p>
                                <h4 class="title"><a href="blog-sngle.html">We Provide you Best &amp; Creative
                                        Consulting
                                        Service</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-brands">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 v-center">
                        <h5 class="mb10">Top App Development Companies</h5>
                        <p>We are believers of change! A change driven by technology and innovation</p>
                    </div>
                    <div class="col-lg-8 v-center">
                        <ul class="footer-badges-">
                            <li><img src="../assets/images/icons/business of apps.png" alt="badges"></li>
                            <li><img src="../assets/images/icons/clutch.png" alt="badges"></li>
                            <li><img src="../assets/images/icons/goodfirms.png" alt="badges"></li>
                            <li><img src="../assets/images/icons/mobile.png" alt="badges"></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer-row3">
            <div class="copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="footer-social-media-icons">
                                <a href="https://www.facebook.com/boxinall" target="blank"><i
                                        class="fab fa-facebook"></i></a>
                                <a href="https://twitter.com/Boxinall?s=09" target="blank"><i
                                        class="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/boxinall_/?igshid=1s)xkxoumwfia" target="blank"><i
                                        class="fab fa-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/boxinall-softech-pvt-ltd/" target="blank"><i
                                        class="fab fa-linkedin"></i></a>

                            </div>
                            <div class="row">
                                <div class="col-lg-12 mb-2">
                                    <a href="https://boxinallsoftech.com/blog/privacy/" class="m-2">Privacy Policy</a>
                                    <a href="https://boxinallsoftech.com/blog/terms/" class="m-2">Terms</a>
                                    <a href="https://boxinallsoftech.com/blog/shipping-policy/" class="m-2">Shipping
                                        Policy</a>
                                    <a href="https://boxinallsoftech.com/blog/return-and-refund-policy-for-boxinall-softech/"
                                        class="m-2">Cancellation</a>
                                    <a href="https://boxinallsoftech.com/contact" class="m-2">Contact </a>
                                </div>
                            </div>
                            <div class="footer-">
                                <p>© 2020-2022. All Rights Reserved By <a href="" target="blank">Boxinall
                                        Softech Pvt Ltd.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'WebHeader',
}
</script>